<template>
  <svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 5.28926H2.21429V3.70248C2.21429 2.99583 2.252 1.86962 2.78571 1.19008C3.34486 0.474446 4.03457 0 5.35714 0C7.51429 0 8.5 0.264463 8.5 0.264463L8.07143 2.77686C7.61233 2.62266 7.13071 2.5335 6.64286 2.5124C5.974 2.5124 5.35714 2.66274 5.35714 3.30579V5.28926H8.21429V7.66942H5.35714V16H2.21429V7.66942H0.5V5.28926Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 5.28926H2.21429V3.70248C2.21429 2.99583 2.252 1.86962 2.78571 1.19008C3.34486 0.474446 4.03457 0 5.35714 0C7.51429 0 8.5 0.264463 8.5 0.264463L8.07143 2.77686C7.61233 2.62266 7.13071 2.5335 6.64286 2.5124C5.974 2.5124 5.35714 2.66274 5.35714 3.30579V5.28926H8.21429V7.66942H5.35714V16H2.21429V7.66942H0.5V5.28926Z"
      fill="currentColor"
    />
  </svg>
</template>
